import * as React from "react"
import Seo from "../components/seo"
import PrivateRoute from "../components/privateRoute"
import ReduxWrapper from "../redux/reduxWrapper"
import Logout from "../components/logout"

const LogoutPage = props => {
  return (
    <React.Fragment>
      <Seo title="Login" />
      <PrivateRoute component={Logout} location={props.location} />
    </React.Fragment>
  )
}

const WrappedPage = props => <ReduxWrapper element={<LogoutPage {...props} />} />
export default WrappedPage
